import React from "react";
import "./style.css";
import { useState, useEffect, useRef } from "react";

export default function Crochet() {
  return (
    <section id="img3" className="crochets">
      <div className="content">
        <p>Crochet</p>
      </div>
    </section>
  );
}
